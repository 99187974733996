import fetch from 'node-fetch'
import { authHeadersFor } from './authUtils'
import { encodeRFC3986URIComponent } from './uiUtils.js'

const hasuraRESTRequestHeaders = (accessToken, userId) => {
    return {
        ...authHeadersFor(accessToken, userId),
        'content-type': 'application/json'
    }
}

export const allStateData = async (accessToken, userId, { test }) => {
    const resRaw = await fetch(`${process.env.VUE_APP_API}/getStates`, {
        method: 'POST',
        headers: hasuraRESTRequestHeaders(accessToken, userId),
        body: JSON.stringify({
            test
        })
    })

    let raw = null

    if (resRaw?.status === 200) {
        // Parse JSON
        raw = (await resRaw.json())?.result ?? null
    }

    return raw
}

export const allActions = async (accessToken, userId, { test }) => {
    const resRaw = await fetch(`${process.env.VUE_APP_API}/getActions`, {
        method: 'POST',
        headers: hasuraRESTRequestHeaders(accessToken, userId),
        body: JSON.stringify({
            test
        })
    })

    let raw = null

    if (resRaw?.status === 200) {
        // Parse JSON
        raw = (await resRaw.json())?.getActions
    }

    return raw
}

export const allTests = async (accessToken, userId) => {
    const resRaw = await fetch(`${process.env.VUE_APP_API}/tests`, {
        method: 'GET',
        headers: hasuraRESTRequestHeaders(accessToken, userId)
    })

    let raw = null

    if (resRaw?.status === 200) {
        // Parse JSON
        raw = (await resRaw.json())?.tests
    }

    return raw
}

export const importTestDataFromFile = async (accessToken, userId, { fileContent, test, fileName, orgAccess = undefined }) => {
    // Note: Large files may cause 500 error from Hasura

    // Send to Hasura
    const resRaw = await fetch(`${process.env.VUE_APP_API}/importDataFromFile`, {
        method: 'POST',
        headers: hasuraRESTRequestHeaders(accessToken, userId),
        body: JSON.stringify({
            fileContent,
            test,
            fileName,
            orgAccess
        })
    })

    let result = null
    let message = null
    let errorData = null

    if (resRaw?.status === 200) {
        // Parse JSON
        result = (await resRaw.json())?.result ?? null
    } else if (resRaw?.status !== 200) {
        const respJson = await resRaw.json()
        message = respJson?.error ?? null
        if (message) {
            // Look for encoded data in error field (Due to Hasura REST endpoint bug: https://github.com/hasura/graphql-engine/issues/4001)
            try {
                errorData = JSON.parse(message) ?? null
                message = errorData?.message ?? message
            } catch {
                //Ignore
            }
        }
    }

    return { result, message, errorData }
}

export const pickAction = async (accessToken, userId, { actionId, session, state }) => {
    // Send to Hasura
    const resRaw = await fetch(`${process.env.VUE_APP_API}/pickAction`, {
        method: 'POST',
        headers: hasuraRESTRequestHeaders(accessToken, userId),
        body: JSON.stringify({
            session,
            actionId,
            state
        })
    })

    let result = null

    if (resRaw?.status === 200) {
        // Parse JSON
        result = (await resRaw.json())?.session ?? null
    }

    return result
}

export const restartSession = async (accessToken, userId, { name }) => {
    // Send to Hasura
    const resRaw = await fetch(`${process.env.VUE_APP_API}/restartSession`, {
        method: 'POST',
        headers: hasuraRESTRequestHeaders(accessToken, userId),
        body: JSON.stringify({
            name
        })
    })

    let result = null

    if (resRaw?.status === 200) {
        // Parse JSON
        result = (await resRaw.json())?.session ?? null
    }

    return result
}

export const rankedActionsForPhrase = async (accessToken, userId, { phrase, session }) => {
    // Send to Hasura
    const resRaw = await fetch(`${process.env.VUE_APP_API}/getRankedActionsForPhrase`, {
        method: 'POST',
        headers: hasuraRESTRequestHeaders(accessToken, userId),
        body: JSON.stringify({
            phrase,
            session
        })
    })

    let result = null
    let message = null

    if (resRaw?.status > 400) {
        // Parse message
        // const response = await resRaw.json()
        result = null 
        message = `No results. There was an internal error (${resRaw.status}). Try again.`
    } else if (resRaw?.status === 400) {
        // Parse message
        // const response = await resRaw.json()
        result = null 
        message = 'No results. Check the test configuration and try again.'
    } else if (resRaw?.status === 200) {
        // Parse JSON
        result = (await resRaw.json())?.result ?? null
        message = null
    }

    return { message, result }
}

export const registerUser = async (accessToken, userId, { email, picture, name }) => {
    // Send to Hasura
    const resRaw = await fetch(`${process.env.VUE_APP_API}/registerUser`, {
        method: 'POST',
        headers: hasuraRESTRequestHeaders(accessToken, userId),
        body: JSON.stringify({
            email,
            picture,
            name
        })
    })

    let result = null

    if (resRaw?.status === 200) {
        // Parse JSON
        result = (await resRaw.json())?.result ?? null
    }

    return result
}

const NLP_IMPORT_CHUNK_SIZE_BYTES = 15000000

export const importNLPDataFromFile = async (accessToken, userId, { fileBuffer, name }, onProgress = () => {}) => {
    // Handle the large fileBuffer in chunks
    const chunkLength = NLP_IMPORT_CHUNK_SIZE_BYTES
    const size = Math.ceil(fileBuffer.length/chunkLength)
    let offset = 0
    let result = null
    for (let i = 0; i < size; i++) {
        const thisChunk = fileBuffer.slice(offset, offset + chunkLength)
        // Send to Hasura
        const resRaw = await fetch(`${process.env.VUE_APP_API}/importNLPModelData`, {
            method: 'POST',
            headers: hasuraRESTRequestHeaders(accessToken, userId),
            body: JSON.stringify({
                fileContent: thisChunk.toString('base64'),
                name,
                firstChunk: i === 0,
                lastChunk: i === size - 1
            })
        })

        if (resRaw?.status === 200) {
            // Parse JSON
            result = (await resRaw.json())?.result ?? null
        }
        offset += chunkLength
        onProgress(100 * (offset / fileBuffer.length))
    }
    return result
}

export const getNLPModels = async (accessToken, userId) => {
    // Send to Hasura
    const resRaw = await fetch(`${process.env.VUE_APP_API}/getNLPModels`, {
        method: 'GET',
        headers: hasuraRESTRequestHeaders(accessToken, userId)
    })

    let result = null

    if (resRaw?.status === 200) {
        // Parse JSON
        result = (await resRaw.json())?.results ?? null
    }

    return result
}

export const getTest = async (accessToken, userId, { name }) => {
    // Send to Hasura
    const resRaw = await fetch(`${process.env.VUE_APP_API}/test/${encodeRFC3986URIComponent(name)}`, {
        method: 'GET',
        headers: hasuraRESTRequestHeaders(accessToken, userId)
    })

    let result = null

    if (resRaw?.status === 200) {
        // Parse JSON
        result = (await resRaw.json())?.result ?? null
    }

    return result
}

export const updateTest = async (accessToken, userId, { name, archived, orgAccess = undefined }) => {
    // Send to Hasura
    const resRaw = await fetch(`${process.env.VUE_APP_API}/test/${encodeRFC3986URIComponent(name)}`, {
        method: 'PATCH',
        headers: hasuraRESTRequestHeaders(accessToken, userId),
        body: JSON.stringify({
            archived,
            orgAccess
        })
    })

    let result = null

    if (resRaw?.status === 200) {
        // Parse JSON
        result = (await resRaw.json())?.result ?? null
    }

    return result
}

export const removeTest = async (accessToken, userId, { name }) => {
    // Send to Hasura
    const resRaw = await fetch(`${process.env.VUE_APP_API}/test/${encodeRFC3986URIComponent(name)}`, {
        method: 'DELETE',
        headers: hasuraRESTRequestHeaders(accessToken, userId)
    })

    let result = null
    let message = null

    if (resRaw?.status > 400) {
        // Parse message
        // const response = await resRaw.json()
        result = null 
        message = `No results. There was an internal error (${resRaw.status}). Try again.`
    } else if (resRaw?.status === 400) {
        // Parse message
        const response = await resRaw.json()
        result = null 
        message = response.error
    } else if (resRaw?.status === 200) {
        // Parse JSON
        result = (await resRaw.json())?.result ?? null
        message = null
    }

    return { message, result }
}

export const allSessionGroups = async (accessToken, userId) => {
    const resRaw = await fetch(`${process.env.VUE_APP_API}/groups`, {
        method: 'GET',
        headers: hasuraRESTRequestHeaders(accessToken, userId)
    })

    let raw = null

    if (resRaw?.status === 200) {
        // Parse JSON
        raw = (await resRaw.json())?.result
    }

    return raw
}

export const createSession = async (accessToken, userId, { name, test, org }) => {
    const resRaw = await fetch(`${process.env.VUE_APP_API}/session`, {
        method: 'POST',
        headers: hasuraRESTRequestHeaders(accessToken, userId),
        body: JSON.stringify({
            name,
            test,
            org
        })
    })

    let result = null

    if (resRaw?.status === 200) {
        // Parse JSON
        result = (await resRaw.json())?.result ?? null
    }

    return result
}

export const getSession = async (accessToken, userId, { name }) => {
    // Send to Hasura
    const resRaw = await fetch(`${process.env.VUE_APP_API}/session/${encodeRFC3986URIComponent(name)}`, {
        method: 'GET',
        headers: hasuraRESTRequestHeaders(accessToken, userId)
    })

    let result = null

    if (resRaw?.status === 200) {
        // Parse JSON
        result = (await resRaw.json())?.result ?? null
    }

    return result
}

export const updateSession = async (accessToken, userId, { 
    name,
    test,
    archived,
    roleAssignments,
    roleAssigneeNames,
    roleAssigneeTitles,
    spectators, 
    dispatched, 
    scheduledTime, 
    nlpEnabled, 
    nlpMatchThreshold, 
    nlpModel, 
    nlpAutoDisableThreshold, 
    variables, 
    nlpContext 
}) => {
    // Send to Hasura
    const resRaw = await fetch(`${process.env.VUE_APP_API}/session/${encodeRFC3986URIComponent(name)}`, {
        method: 'PATCH',
        headers: hasuraRESTRequestHeaders(accessToken, userId),
        body: JSON.stringify({
            test,
            archived,
            roleAssignments,
            roleAssigneeNames,
            roleAssigneeTitles,
            spectators,
            dispatched,
            scheduledTime,
            nlpEnabled,
            nlpContext,
            nlpMatchThreshold,
            nlpModel,
            nlpAutoDisableThreshold,
            variables
        })
    })

    let result = null

    if (resRaw?.status === 200) {
        // Parse JSON
        result = (await resRaw.json())?.result ?? null
    }

    return result
}

export const addSessionMessage = async (accessToken, userId, { session, message }) => {
    // Send to Hasura
    const resRaw = await fetch(`${process.env.VUE_APP_API}/session/${encodeRFC3986URIComponent(session)}/message`, {
        method: 'POST',
        headers: hasuraRESTRequestHeaders(accessToken, userId),
        body: JSON.stringify({
            message
        })
    })

    let result = null

    if (resRaw?.status === 200) {
        // Parse JSON
        result = (await resRaw.json())?.result ?? null
    }

    return result
}

export const allOrgs = async (accessToken, userId) => {
    const resRaw = await fetch(`${process.env.VUE_APP_API}/orgs`, {
        method: 'GET',
        headers: hasuraRESTRequestHeaders(accessToken, userId)
    })

    let raw = null

    if (resRaw?.status === 200) {
        // Parse JSON
        raw = (await resRaw.json())?.results
    }

    return raw
}

export const createOrg = async (accessToken, userId, { id, displayName, sponsorName, sponsorEmail, logoURL, archived }) => {
    const resRaw = await fetch(`${process.env.VUE_APP_API}/org`, {
        method: 'POST',
        headers: hasuraRESTRequestHeaders(accessToken, userId),
        body: JSON.stringify({
            id,
            displayName,
            sponsorName,
            sponsorEmail,
            logoURL,
            archived
        })
    })

    let result = null

    if (resRaw?.status === 200) {
        // Parse JSON
        result = (await resRaw.json())?.result ?? null
    }

    return result
}

export const updateOrg = async (accessToken, userId, { id, displayName, sponsorName, sponsorEmail, logoURL, archived }) => {
    // Send to Hasura
    const resRaw = await fetch(`${process.env.VUE_APP_API}/org/${encodeURIComponent(id)}`, {
        method: 'PATCH',
        headers: hasuraRESTRequestHeaders(accessToken, userId),
        body: JSON.stringify({
            displayName,
            sponsorEmail,
            sponsorName,
            logoURL,
            archived
        })
    })

    let result = null

    if (resRaw?.status === 200) {
        // Parse JSON
        result = (await resRaw.json())?.result ?? null
    }

    return result
}

export const allSponsors = async (accessToken, userId) => {
    const resRaw = await fetch(`${process.env.VUE_APP_API}/sponsors`, {
        method: 'GET',
        headers: hasuraRESTRequestHeaders(accessToken, userId)
    })

    let raw = null

    if (resRaw?.status === 200) {
        // Parse JSON
        raw = (await resRaw.json())?.results
    }

    return raw
}

export const addSponsor = async (accessToken, userId, { email, name, orgId }) => {
    let result = null
    if (email && name && orgId) {
        const resRaw = await fetch(`${process.env.VUE_APP_API}/org/${orgId}/sponsors`, {
            method: 'POST',
            headers: hasuraRESTRequestHeaders(accessToken, userId),
            body: JSON.stringify({
                email,
                name
            })
        })
    
        if (resRaw?.status === 200) {
            // Parse JSON
            result = (await resRaw.json())?.result ?? null
        }
    }
    
    return result
}

export const updateSponsor = async (accessToken, userId, { sponsorUserId, blocked }) => {
    let result = null
    if (sponsorUserId && (blocked === true || blocked === false)) {
        const resRaw = await fetch(`${process.env.VUE_APP_API}/sponsor/${sponsorUserId}`, {
            method: 'PATCH',
            headers: hasuraRESTRequestHeaders(accessToken, userId),
            body: JSON.stringify({
                blocked
            })
        })
    
        if (resRaw?.status === 200) {
            // Parse JSON
            result = (await resRaw.json())?.result ?? null
        }
    }
    return result
}

export const getUserData = async (accessToken, userId, { emails }) => {
    // Send to Hasura
    const resRaw = await fetch(`${process.env.VUE_APP_API}/userData`, {
        method: 'POST',
        headers: hasuraRESTRequestHeaders(accessToken, userId),
        body: JSON.stringify({
            emails
        })
    })

    let result = null

    if (resRaw?.status === 200) {
        // Parse JSON
        result = (await resRaw.json())?.results ?? null
    }

    return result
}

export const reInviteUser = async (accessToken, userId, { session, email }) => {
    // Send to Hasura
    const resRaw = await fetch(`${process.env.VUE_APP_API}/userInvite`, {
        method: 'POST',
        headers: hasuraRESTRequestHeaders(accessToken, userId),
        body: JSON.stringify({
            email,
            session
        })
    })

    let result = null

    if (resRaw?.status === 200) {
        // Parse JSON
        result = (await resRaw.json())?.result ?? null
    }

    return result
}

export const allNLPMetas = async (accessToken, userId) => {
    const resRaw = await fetch(`${process.env.VUE_APP_API}/nlpMetas`, {
        method: 'GET',
        headers: hasuraRESTRequestHeaders(accessToken, userId)
    })

    let raw = null

    if (resRaw?.status === 200) {
        // Parse JSON
        raw = (await resRaw.json())?.result
    }

    return raw
}

export const insertNLPMeta = async (accessToken, userId, { name, content, type }) => {
    const resRaw = await fetch(`${process.env.VUE_APP_API}/nlpMeta`, {
        method: 'POST',
        headers: hasuraRESTRequestHeaders(accessToken, userId),
        body: JSON.stringify({
            name,
            type,
            content
        })
    })

    let raw = null

    if (resRaw?.status === 200) {
        // Parse JSON
        raw = (await resRaw.json())?.result
    }

    return raw
}

export const updateNLPMeta = async (accessToken, userId, { name, content, type }) => {
    const resRaw = await fetch(`${process.env.VUE_APP_API}/nlpMeta/${encodeURIComponent(name)}`, {
        method: 'PATCH',
        headers: hasuraRESTRequestHeaders(accessToken, userId),
        body: JSON.stringify({
            type,
            content
        })
    })

    let raw = null

    if (resRaw?.status === 200) {
        // Parse JSON
        raw = (await resRaw.json())?.result
    }

    return raw
}

export const getAttachmentSource = async (accessToken, userId, { test, action, state, name, extension }) => {
    // Send to Hasura
    let sourceURL
    if (action) {
        sourceURL = `${process.env.VUE_APP_API}/test-action-attachments/${encodeRFC3986URIComponent(test)}/${encodeRFC3986URIComponent(action)}/${encodeRFC3986URIComponent(name)}/${encodeRFC3986URIComponent(extension)}`
    } else if (state) {
        sourceURL = `${process.env.VUE_APP_API}/test-state-attachments/${encodeRFC3986URIComponent(test)}/${encodeRFC3986URIComponent(state)}/${encodeRFC3986URIComponent(name)}/${encodeRFC3986URIComponent(extension)}`
    }

    const resRaw = await fetch(sourceURL, {
        method: 'GET',
        headers: hasuraRESTRequestHeaders(accessToken, userId)
    })

    let result = null

    if (resRaw?.status === 200) {
        // Parse JSON
        const attachment = (await resRaw.json())?.result ?? null

        // Build src string
        if (attachment?.data) {
            result = `data:image/${extension}};base64,${attachment.data}`
        }
    }

    return result
}

export const generateScenario = async (accessToken, userId, args) => {
    // Send to Hasura
    const resRaw = await fetch(`${process.env.VUE_APP_API}/generate-scenario`, {
        method: 'POST',
        headers: hasuraRESTRequestHeaders(accessToken, userId),
        body: JSON.stringify(args)
    })

    let result = null
    let message = null

    if (resRaw?.status === 200) {
        // Parse JSON
        result = (await resRaw.json())?.result ?? null
    } else if (resRaw?.status !== 200) {
        const respJson = await resRaw.json()
        message = respJson?.error ?? `An unknown error has occurred. (Code: ${resRaw.status})`
    }

    return { result, message }
}