<template>
  <Splash v-if="currentPath === '/'"></Splash>
  <ScenarioNotice
      v-else-if="currentPath === '/error'"
      :height="height"
      :prompt="queryParams.error"
      :message="queryParams.error_description"
      :isDarkTheme="darkThemeSwitch">
  </ScenarioNotice>
  <v-app v-else :theme="currentTheme">
    <!-- <v-navigation-drawer app>
    </v-navigation-drawer> -->

    <v-app-bar app :height="(isManager || isSponsor) ? (smAndUp ? 169 : 412) : undefined">
      <template v-slot:image>
        <v-img v-if="!darkThemeSwitch"
          gradient="to right, rgba(255,255,255,0.9), rgba(241,144,37,0.7301121132046569)">
        </v-img>
        <v-img v-if="darkThemeSwitch"
          gradient="to right, rgba(0,0,0,0.7301121132046569), rgba(128,127,126,0.7301121132046569), rgba(0,0,0,0.7301121132046569)">
        </v-img>
      </template>
      <v-container :style="{ 'padding-top': (mdAndUp ? 16 : 0), 'padding-bottom': (mdAndUp ? 16 : 0) }">
        <v-row>
          <v-col cols="12">
            <div class="d-flex justify-start align-center flex-column flex-sm-row fill-height">
              <div class="d-flex justify-center align-center flex-column fill-height">
                <v-img
                  :width="210"
                  :max-height="40"
                  :src="darkThemeSwitch ? require('@/assets/logos/logo_placeholder.png') : require('@/assets/logos/logo_placeholder_black.png')"
                ></v-img>
                <div v-if="isManager || isSponsor" :class="['text-h8']">{{ componentSubTitle }}</div>
              </div>
              <!-- TODO: There's probably a better way to justify this using a proper grid -->
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <!-- User Logged In -->
              <v-menu open-on-hover v-if="isAuthenticated">
                <template v-slot:activator="{ props }">
                  <v-btn class="ml-3 mr-3" :variant="user.picture ? 'plain' : 'outlined'" icon size="x-small" color="grey" v-bind="props">
                    <v-img v-if="user.picture" contain width="30" :src="user.picture" />
                    <v-icon v-else>
                      mdi-account-outline
                    </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item disabled>
                    <template v-slot:prepend>
                      <v-icon icon="mdi-account"></v-icon>
                    </template>
                    <v-list-item-title>{{ user.name }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="logInOrOut">
                    <v-list-item-title>Log Out</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <!-- No User Logged In -->
              <v-btn class="ml-3 mr-3" v-if="!isAuthenticated" variant="outlined" size="x-small" prepend-icon="mdi-account-outline"
                color="grey" @click="logInOrOut">
                Login
              </v-btn>
              <v-btn class="ml-3 mr-3" variant="outlined" size="x-small" prepend-icon="mdi-link-box-variant"
                :color="darkThemeSwitch ? 'grey' : 'grey-darken-2'" @click="visitClientApp">
                Go To Client App
              </v-btn>
              <v-switch v-model="darkThemeSwitch" hide-details
                :prepend-icon="darkThemeSwitch ? 'mdi-weather-night' : 'mdi-white-balance-sunny'"></v-switch>
            </div>
          </v-col>
        </v-row>
        <template v-if="isManager || isSponsor">
          <v-row cols="12">
            <v-col><v-spacer></v-spacer></v-col>
            <v-col class="d-flex justify-center">
                  <v-autocomplete
                    class="mr-8"
                    style="width: 600px; min-width: 200px; max-height: 50px;"
                    :items="allSessionIds"
                    prepend-icon="mdi-chess-rook"
                    density="compact"
                    label="Game"
                    variant="outlined"
                    hide-details="auto"
                    no-data-text="No Matching Games Found..."
                    v-model="selectedSession"
                    append-icon="mdi-plus"
                    @click:append="showNewTestDialog = true"
                    >
                  </v-autocomplete>
                  <v-tabs height="50px" v-model="selectedTab" :direction="smAndUp ? 'horizontal' : 'vertical'" fixed-tabs>
                    <v-tab v-if="isManager" value="states">Turns</v-tab>
                    <v-tab v-if="isManager" value="actions">Actions</v-tab>
                    <v-tab value="player">Preview</v-tab>
                    <v-tab value="settings">⚙️Settings</v-tab>
                  </v-tabs>
                </v-col>
              <v-col><v-spacer></v-spacer></v-col>
          </v-row>
        </template>
      </v-container>
    </v-app-bar>

    <!-- Sizes your content based upon application components -->
    <v-main>
      <!-- Provides the application the proper gutter -->
      <Player
            v-if="isUser && !isLoading"
            :height="(windowHeight - 125)"
            :user="user"
            :isDarkTheme="darkThemeSwitch"
            :testSession="testSession"
            :nlpModels="nlpModels"
            :nlpMetas="nlpMetas"
            :variables="testVariables"
            :submitActionFunc="submitAction"
            :resetSessionFunc="resetSession"
            :getMatchingActionsFunc="getMatchingActionsFunc"
            :submitSessionSettingsFunc="submitSessionSettings"
            :getUserDataFunc="getUserStatus"
            :inviteUserFunc="inviteUserToSession"
            :attachmentSourceLoaderFunc="loadAttachmentSource"
          />
      <v-window v-if="isManager || isSponsor" v-model="selectedTab">
        <v-window-item v-if="isManager" value="states" reverse-transition="fade-transition" transition="fade-transition">
          <v-network-graph style="height: 300px;" :nodes="stateNodes" :edges="stateTransitions" :configs="stateConfigs">
            <!-- Use CSS to define references to external fonts.
                   To use CSS within SVG, use <defs>. -->
            <defs>
              <!-- Cannot use <style> directly due to restrictions of Vue. -->
              <component :is="'style'">
                @font-face { font-family: 'Material Icons'; font-style: normal; font-weight:
                400; src:
                url(https://fonts.gstatic.com/s/materialicons/v97/flUhRq6tzZclQEJ-Vdg-IuiaDsNcIhQ8tQ.woff2)
                format('woff2'); }
              </component>
            </defs>
          
            <!-- Replace the node component -->
            <template #override-node="{ nodeId, scale, config, ...slotProps }">
              <circle :r="config.radius * scale" :fill="config.color" v-bind="slotProps" />
              <!-- Use v-html to interpret escape sequences for icon characters. -->
              <!-- eslint-disable-next-line vue/no-v-text-v-html-on-component -->
              <text font-family="Material Icons" :font-size="22 * scale" fill="#ffffff" text-anchor="middle" dominant-baseline="central" style="pointer-events: none" v-html="stateNodes[nodeId].icon" />
            </template>
          </v-network-graph>
          <v-container fluid style="overflow-y: auto;">
            <v-row dense v-for="(item, index) of states" :key="index">
              <v-col cols="">
                <v-card>
                  <div class="d-flex justify-space-between">
                    <div>
                      <v-card-title class="text-h5" style="text-align: left;">
                        {{ item.name }} {{ item.origin ? '( Origin )' : '' }}
                      </v-card-title>
                      <v-card-text class="mt-2 mb-2" style="text-align: left;">Prompt: {{ item.prompt }}
                      </v-card-text>
                    </div>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-window-item>

        <v-window-item v-if="isManager" value="actions" reverse-transition="fade-transition" transition="fade-transition">
          <v-container fluid>
            <v-row dense v-for="(item, index) of actions" :key="index">
              <v-col cols="">
                <v-card>
                  <div class="d-flex justify-space-between">
                    <div>
                      <v-card-title class="text-h5" style="text-align: left;">
                        ID: {{ item.id }}
                      </v-card-title>
                      <v-card-subtitle style="text-align: left;">Time: {{ item.time }} mins
                      </v-card-subtitle>
                      <v-card-text class="mt-0 mb-0 pt-0 pb-0" style="text-align: left;">Action: {{ item.action }}
                      </v-card-text>
                      <v-card-text class="mt-0 mb-0 pt-0 pb-0" style="text-align: left;">Answer: {{ item.answer }}
                      </v-card-text>
                    </div>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-window-item>

        <v-window-item value="player" reverse-transition="fade-transition" transition="fade-transition">
          <Player
            v-if="!isLoading"
            :height="(windowHeight - (smAndUp ? 169 + 76 : 412 + 44))"
            :user="user"
            :isDarkTheme="darkThemeSwitch"
            :testSession="testSession"
            :nlpModels="nlpModels"
            :nlpMetas="nlpMetas"
            :variables="testVariables"
            :submitActionFunc="submitAction"
            :resetSessionFunc="resetSession"
            :getMatchingActionsFunc="getMatchingActionsFunc"
            :submitSessionSettingsFunc="submitSessionSettings"
            :scenarios="allScenarios"
            :pickScenarioFunc="updateSessionScenario"
            :postMessageFunc="postMessage"
            :getUserDataFunc="getUserStatus"
            :inviteUserFunc="inviteUserToSession"
            :canRestart="true"
            :attachmentSourceLoaderFunc="loadAttachmentSource"
          />
          <div v-else class="d-flex flex-column align-center justify-center" :style="{ height: (windowHeight - ((smAndUp ? 169 + 76 : 412 + 44))) + 'px' }">
            <v-progress-circular size="96" width="8" indeterminate></v-progress-circular>
          </div>
        </v-window-item>

        <v-window-item value="settings" reverse-transition="fade-transition" transition="fade-transition">
          <v-container fluid>
            <v-row dense cols="12">
              <v-col>
                <v-sheet class="pa-12">
                  <v-container v-if="isManager" fluid class="pa-0 ma-0">
                    <v-row dense cols="12">
                      <v-col cols="12" md="6">
                        <v-sheet :elevation="4" class="mx-auto">
                          <v-card height="400">
                            <div class="d-flex justify-start align-center">
                              <v-card-title class="mr-0 pr-0">Organizations</v-card-title>
                              <v-btn
                                class="ml-0 pl-0"
                                icon="mdi-plus"
                                variant="plain"
                                @click="newOrgButtonClicked"
                              ></v-btn>
                            </div>
                            <v-list v-if="orgs.length > 0" elevation="2" density="compact" class="mx-2 my-2 rounded-lg" style="height: 325px; overflow-y: scroll;">
                              <v-list-item
                                v-for="org in orgs"
                                :key="org.id"
                                prepend-icon="mdi-account-group"
                              >
                                <v-list-item-title>{{ `${org.archived ? `${org.id} (Archived)`: org.id}` }}</v-list-item-title>
                                <v-list-item-subtitle> {{ `Display Name: ${org.displayName ?? ''}` }}</v-list-item-subtitle>
                                <v-list-item-subtitle> {{ `Updated: ${new Date(org.updated).toISOString()}` }}</v-list-item-subtitle>
                                <template v-slot:append>
                                  <v-btn
                                    icon="mdi-account-edit"
                                    variant="plain"
                                    @click="editSelectedOrg(org)"
                                  ></v-btn>
                                </template>
                              </v-list-item>
                            </v-list>
                            <v-sheet v-else class="mx-2 my-2 rounded-lg">
                              <div>No Organizations Have Been Added.</div>
                            </v-sheet>
                          </v-card>
                        </v-sheet>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-sheet :elevation="4" class="mx-auto">
                          <v-card height="400">
                            <div class="d-flex justify-start align-center">
                              <v-card-title class="mr-0 pr-0">Organization Sponsors</v-card-title>
                              <v-btn
                                class="ml-0 pl-0"
                                icon="mdi-plus"
                                variant="plain"
                                @click="newSponsorButtonClicked"
                              ></v-btn>
                            </div>
                            <v-list v-if="sponsors.length > 0" elevation="2" density="compact" class="mx-2 my-2 rounded-lg" style="height: 325px; overflow-y: scroll;">
                              <v-list-item
                                v-for="sponsor in sponsors"
                                :key="sponsor.user_id"
                              >
                                <v-list-item-title :style="{ 'color': (sponsor.blocked === true ? 'red' : undefined) }">{{ `${sponsor.blocked === true ? `${sponsor.name} (Blocked)` : sponsor.name}` }}</v-list-item-title>
                                <v-list-item-subtitle> {{ `Email: ${sponsor.email}` }}</v-list-item-subtitle>
                                <v-list-item-subtitle> {{ `Org: ${nameForOrgId(sponsor.app_metadata?.orgId) ?? 'None'}` }}</v-list-item-subtitle>
                                <v-list-item-subtitle> {{ `Created: ${new Date(sponsor.created_at).toISOString()}` }}</v-list-item-subtitle>
                                <v-list-item-subtitle> {{ `Updated: ${new Date(sponsor.updated_at).toISOString()}` }}</v-list-item-subtitle>
                                <v-list-item-subtitle> {{ `Logins: ${sponsor.logins_count ?? 0 }` }}</v-list-item-subtitle>
                                <v-list-item-subtitle> {{ `Last Login: ${ sponsor.last_login ? new Date(sponsor.last_login).toISOString() : 'Never'}` }}</v-list-item-subtitle>
                                <v-list-item-subtitle> {{ `Last IP: ${sponsor.last_ip ? sponsor.last_ip : 'None'}` }}</v-list-item-subtitle>
                                <template v-slot:prepend>
                                  <div class="d-flex justify-center align-center pa-2">
                                    <v-img v-if="sponsor.picture" width="80" :src="sponsor.picture" />
                                    <v-icon v-else>
                                      mdi-account-star
                                    </v-icon>
                                  </div>
                                </template>
                                <template v-slot:append>
                                  <div class="d-flex flex-column">
                                    <v-btn
                                        v-if="sponsor.blocked === true"
                                        prepend-icon="mdi-account-check"
                                        size="x-small"
                                        variant="outlined"
                                        @click="unblockSponsor(sponsor)"
                                      >Unblock</v-btn>
                                    <v-btn
                                        v-else
                                        prepend-icon="mdi-account-cancel"
                                        size="x-small"
                                        variant="outlined"
                                        @click="blockSponsor(sponsor)"
                                      >Block</v-btn>
                                    <!-- <v-menu>
                                      <template v-slot:activator="{ props }">
                                        <v-btn
                                          v-bind="props"
                                          class="mt-2"
                                          prepend-icon="mdi-delete"
                                          color="error"
                                          size="x-small"
                                          variant="outlined"
                                        >Delete</v-btn>
                                      </template>
                                      <v-list base-color="error">
                                        <v-list-item
                                          :key="0"
                                          :value="0"
                                          :disabled="true"
                                        >
                                          <v-list-item-title>Are You Sure?</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item
                                          :key="1"
                                          :value="1"
                                        >
                                          <v-list-item-title>No</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item
                                          :key="2"
                                          :value="2"
                                          @click="removeSponsor(sponsor)"
                                        >
                                          <v-list-item-title>Yes, Delete Sponsor</v-list-item-title>
                                        </v-list-item>
                                      </v-list>
                                    </v-menu> -->
                                  </div>
                                </template>
                              </v-list-item>
                            </v-list>
                            <v-sheet v-else class="mx-2 my-2 rounded-lg">
                              <div>No Sponsors Have Been Invited.</div>
                            </v-sheet>
                          </v-card>
                        </v-sheet>
                      </v-col>
                    </v-row>
                    <v-row dense cols="12">
                      <v-col cols="12">
                        <v-sheet :elevation="4" class="mx-auto">
                          <v-card height="400">
                            <div class="d-flex justify-start align-center">
                              <v-card-title class="mr-0 pr-0">Installed Scenarios</v-card-title>
                              <v-btn
                                class="ml-2"
                                @click="newScenarioButtonClicked"
                              >Import</v-btn>
                            </div>
                            <v-list v-if="tests.length > 0" elevation="2" density="compact" class="mx-2 my-2 rounded-lg" style="height: 325px; overflow-y: scroll;">
                              <v-list-item
                                v-for="test in tests"
                                :key="test.name"
                                :prepend-icon="test.info?.Icon ?? 'mdi-chart-timeline-variant-shimmer'"
                              >
                                <v-list-item-title>{{ test.name }} {{ test.archived === true ? '(Archived)' : '' }}</v-list-item-title>
                                <v-list-item-subtitle> {{ `Display Name: ${test.info?.Name ?? ''}` }}</v-list-item-subtitle>
                                <v-list-item-subtitle> {{ `Description: ${test.info?.Description ?? ''}` }}</v-list-item-subtitle>
                                <v-list-item-subtitle> {{ `File: ${test.fileName}` }}</v-list-item-subtitle>
                                <v-list-item-subtitle> {{ `Org Access: ${test.orgAccess ? `${test.orgAccess.length ? test.orgAccess.join(', ') : 'None'}` : 'All Organizations'}` }}</v-list-item-subtitle>
                                <v-list-item-subtitle> {{ `Updated: ${new Date(test.updated).toISOString()}` }}</v-list-item-subtitle>
                                <template v-slot:append>
                                  <div class="d-flex flex-column">
                                    <v-btn
                                        prepend-icon="mdi-database-edit-outline"
                                        size="x-small"
                                        variant="outlined"
                                        @click="editSelectedScenario(test)"
                                      >Edit</v-btn>
                                    <v-menu>
                                      <template v-slot:activator="{ props }">
                                        <v-btn
                                          v-bind="props"
                                          class="mt-2"
                                          prepend-icon="mdi-delete"
                                          color="error"
                                          size="x-small"
                                          variant="outlined"
                                        >Delete</v-btn>
                                      </template>
                                      <v-list base-color="error">
                                        <v-list-item
                                          :key="0"
                                          :value="0"
                                          :disabled="true"
                                        >
                                          <v-list-item-title>Are You Sure?</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item
                                          :key="1"
                                          :value="1"
                                        >
                                          <v-list-item-title>No</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item
                                          :key="2"
                                          :value="2"
                                          @click="removeSelectedTest(test)"
                                        >
                                          <v-list-item-title>Yes, Delete It</v-list-item-title>
                                        </v-list-item>
                                      </v-list>
                                    </v-menu>
                                  </div>
                                </template>
                              </v-list-item>
                            </v-list>
                            <v-sheet v-else class="mx-2 my-2 rounded-lg">
                              <div>No Scenarios Installed.</div>
                            </v-sheet>
                            <v-snackbar
                                :theme="darkThemeSwitch ? 'light' : 'dark'"
                                v-model="scenarioNotifVisible"
                                :timeout="scenarioNotifTimeout"
                                :top="true"
                                >
                                <span :class="darkThemeSwitch ? undefined : 'text-white'">
                                    {{ scenarioNotifText }}
                                </span>
                            </v-snackbar>
                          </v-card>
                        </v-sheet>
                      </v-col>
                    </v-row>
                    <v-row dense cols="12">
                      <v-col cols="12" md="6">
                        <v-sheet :elevation="4" class="mx-auto">
                          <v-card height="200">
                            <v-card-title>Installed NLP Models</v-card-title>
                            <v-list v-if="nlpModels.length > 0" elevation="2" density="compact" class="mx-2 my-2 rounded-lg" style="height: 125px; overflow-y: scroll;">
                              <v-list-item
                                v-for="model in nlpModels"
                                :key="model.name"
                                :prepend-icon="'mdi-view-module'"
                              >
                                <v-list-item-title>{{ model.name }}</v-list-item-title>
                                <v-list-item-subtitle> {{ `Type: ${model.type}` }}</v-list-item-subtitle>
                                <v-list-item-subtitle> {{ `Installed: ${model.created}` }}</v-list-item-subtitle>
                                <v-list-item-subtitle> {{ `Modified: ${model.modified}` }}</v-list-item-subtitle>
                              </v-list-item>
                            </v-list>
                            <v-sheet v-else class="mx-2 my-2 rounded-lg">
                              <div>No Models Installed.</div>
                            </v-sheet>
                            </v-card>
                        </v-sheet>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-sheet :elevation="4" class="mx-auto">
                          <v-card height="200">
                            <v-card-title>Import NLP Model Data</v-card-title>
                            <template v-if="!isNLPImporting">
                              <v-card-text class="font-weight-bold pb-1">Note: Importing the same model (folder name) will replace
                                any existing data!</v-card-text>
                              <v-card-text class="pt-0">Select a zip file (.zip) containing the model folder.</v-card-text>
                            </template>
                            <template v-else>
                              <v-card-text>{{ nlpImportStatusMsg }}</v-card-text>
                            </template>
                            <input type="file" ref="nlpImporterRef" multiple :disabled="isNLPImporting" @change="importNLPFiles" accept=".zip"
                              class="pl-4 input-file">
                          </v-card>
                        </v-sheet>
                      </v-col>
                    </v-row>
                    <v-row dense cols="12">
                      <v-col cols="12" md="6">
                        <v-sheet :elevation="4" class="mx-auto">
                          <NLPDataList
                            :nlpMetas="nlpMetas"
                            :fetchNLPMetasFunc="fetchNLPMetas"
                            :isDarkTheme="darkThemeSwitch"
                            ></NLPDataList>
                        </v-sheet>
                      </v-col>
                    </v-row>
                  </v-container>
                  <ScenarioSetup
                    v-if="testSession?.dispatched || testSession?.scheduledTime"
                    :height="750"
                    :isDarkTheme="darkThemeSwitch"
                    :isUpdateContext="true"
                    :inlineComponent="true"
                    :assignSettingsFunc="updateSessionSettings"
                    :getUserDataFunc="getUserStatus"
                    :inviteUserFunc="inviteUserToSession"
                    :nlpModels="nlpModels"
                    :nlpMetas="nlpMetas"
                    :variables="testVariables"
                    :testSession="testSession"></ScenarioSetup>
                </v-sheet>
              </v-col>
            </v-row>
          </v-container>
        </v-window-item>
      </v-window>
      <NewGameDialog v-model="showNewTestDialog" :orgs="orgs" :showOrgs="isManager" @new-game-saved="newGameSaved"/>
      <NewSponsorDialog v-model="showSponsorEditDialog" :orgs="orgs" @new-sponsor-saved="sponsorSaved"/>
      <OrgEditDialog v-model="showOrgEditDialog" :editOrg="selectedOrg" @org-saved="orgSaved" />
      <ScenarioEditDialog v-model="showScenarioEditDialog" :editScenario="selectedScenario" :orgs="orgs" @scenario-saved="scenarioSaved" />
    </v-main>

    <v-footer app>
      <v-container :style="{ 'padding-top': (mdAndUp ? 16 : 0), 'padding-bottom': (mdAndUp ? 16 : 0) }">
        <v-row justify="center" align="center" no-gutters>
          <v-col justify-self="center" cols="12">
            <div class="d-flex justify-start align-center flex-column fill-height">
              <v-btn size="x-small" variant="plain" disabled>
                v{{ projectVersion }}
              </v-btn>
              <strong>{{ footerCopywriteHolder }} {{ new Date().getFullYear() }}</strong>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
  </v-app>
</template>

<script>
import { onBeforeUnmount, onMounted, onErrorCaptured, inject, reactive, ref, watch } from 'vue'
import { useRoute, useRouter } from "vue-router"
import { useAuth0 } from '@auth0/auth0-vue'
import { useDisplay } from 'vuetify'
import {
  importTestDataFromFile,
  importNLPDataFromFile,
  allStateData,
  allActions,
  allTests,
  getNLPModels,
  restartSession,
  pickAction,
  rankedActionsForPhrase,
  registerUser,
  getTest,
  updateTest,
  removeTest,
  allSessionGroups,
  getSession,
  updateSession,
  addSessionMessage,
  allOrgs,
  allSponsors,
  updateSponsor,
  getUserData,
  reInviteUser,
  allNLPMetas,
  getAttachmentSource
} from './utils/data.js'
import { Buffer } from 'buffer'
import * as vNG from "v-network-graph"
import { nodesForStates, edgesForStateTransitions } from './utils/graphUtils.js'
import Player from './components/Player.vue'
import Splash from './components/Splash.vue'
import ScenarioSetup from './components/ScenarioSetup.vue'
import NewGameDialog from './components/NewGameDialog.vue'
import OrgEditDialog from './components/OrgEditDialog.vue'
import ScenarioNotice from './components/ScenarioNotice.vue'
import NewSponsorDialog from './components/NewSponsorDialog.vue'
import ScenarioEditDialog from './components/ScenarioEditDialog.vue'
import NLPDataList from './components/NLPDataList.vue'
import { isEmailUser } from './utils/authUtils.js'
import { encodeRFC3986URIComponent } from './utils/uiUtils.js'
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Admin',
  metaInfo() {
    return {
    // // Children can override the title.
    // title: `Vue-SPA-Template v${process.env.PACKAGE_VERSION}`,
    // // Result: My Page Title ← My Site
    // // If a child changes the title to "My Other Page Title",
    // // it will become: My Other Page Title ← My Site
    // // titleTemplate: '%s ← My Site',
    // // Define meta tags here.
    // meta: [
    //   {name: 'description', content: 'Description of SPA.'},
    //   // OpenGraph data (Most widely used)
    //   {property: 'og:title', content: 'Description of SPA.'},
    //   {property: 'og:site_name', content: 'Description of SPA.'},
    //   // The list of types is available here: http://ogp.me/#types
    //   {property: 'og:type', content: 'website'},
    //   // Should the the same as your canonical link, see below.
    //   {property: 'og:url', content: 'https://www.prod-spa-site.com/'},
    //   {property: 'og:image', content: 'https://www.prod-spa-site.com/favicon.png'},
    //   // Often the same as your meta description, but not always.
    //   {property: 'og:description', content: 'Description of SPA'},
    //   // Google / Schema.org markup:
    //   {itemprop: 'name', content: `Vue-SPA-Template v${process.env.PACKAGE_VERSION}`},
    //   {itemprop: 'description', content: 'Description of SPA.'},
    //   {itemprop: 'image', content: 'https://www.prod-spa-site.com/favicon.png'}
    // ],
    // link: [
    //   {rel: 'canonical', href: 'https://www.prod-spa-site.com/'}
    // ]
  }
  },
  components: {
    Player, Splash, ScenarioSetup, NewGameDialog, OrgEditDialog, NewSponsorDialog, ScenarioNotice, NLPDataList, ScenarioEditDialog
  },
  setup() {
    // Logger
    const logger = inject('vuejs3-logger')

    // Auth0
    const { getAccessTokenSilently, loginWithRedirect, logout, user, isAuthenticated } = useAuth0()

    // Router
    const router = useRouter()
    const route = useRoute()
    const queryParams = reactive({})

    // Internal Links
    const currentPath = ref('/')
    watch(
      () => route.path,
      newPath => {
        // Parse the params
        const url = new URL(window.location.href)
        const params = Object.fromEntries(url.searchParams)
        Object.keys(queryParams).forEach(key => delete queryParams[key])
        Object.assign(queryParams, params)
        const hashParams = new URLSearchParams(url.hash)
        const state = hashParams.get('state')
        logger.debug(`Route path changed to: ${JSON.stringify(newPath)}, with params: ${JSON.stringify(params)}, with state: ${state} (URL: ${url})`)
        // Check for the email invite game id
        try {
          if (state) {
            const decodedBuffer = Buffer.from(state, 'base64')
            const encodedState = decodedBuffer.toString()
            const stateObj = JSON.parse(encodedState)
            if (stateObj?.game) {
              logger.debug(`Setting test/session from url state: ${stateObj.game}`)
              visitRouteForSession(stateObj.game)
              return
            }
          }
        } catch { /* */ }
        currentPath.value = newPath
        if (route.params.navSession) {
          logger.debug(`Setting session with path: ${route.params.navSession}`)
          selectedSession.value = route.params.navSession
          // Refetch all items from server
          fetchTestData()
        }
      },
      { immediate: true }
    )

    const checkRouteAndPush = (newRoute) => {
      if (currentPath.value !== newRoute) {
        router.push(newRoute)
      }
    }

    const visitHome = () => {
      checkRouteAndPush('/')
    }

    const visitAdmin = () => {
      checkRouteAndPush('/admin')
    }

    const visitRouteForSession = (name) => {
      checkRouteAndPush(`/t/${encodeRFC3986URIComponent(name)}`)
    }

    const visitClientApp = () => {
      window.location.href = `${process.env.VUE_APP_ORIGIN}/client/games`
    }

    // App Constants
    const componentTitle = ref('ChaosTrack')
    const footerCopywriteHolder = ref('ChaosTrack')
    const componentSubTitle = ref('Admin Portal')
    const projectVersion = ref(process.env.PACKAGE_VERSION || '0')

    // Window Size Mgmt
    const { smAndUp, mdAndUp } = useDisplay()
    const windowHeight = ref(window.innerHeight)
    const windowWidth = ref(window.innerWidth)

    watch(windowHeight, (newHeight, oldHeight) => {
      logger.debug(`Window height: ${oldHeight} --> ${newHeight}`)
    })
    watch(windowWidth, (newWidth, oldWidth) => {
      logger.debug(`Window width: ${oldWidth} --> ${newWidth}`)
    })

    const windowResizeHandler = () => {
      windowHeight.value = window.innerHeight
      windowWidth.value = window.innerWidth
    }

    // Tab Management
    const selectedSession = ref(null)
    const selectedTab = ref('player')

    // Test Data
    const allSessionIds = ref([])
    const tests = ref([])
    const orgs = ref([])
    const sponsors = ref([])
    const states = ref([])
    const actions = ref([])
    const nlpModels = ref([])
    const nlpMetas = ref([])
    const testVariables = ref([])
    const testSession = reactive({}) // The persistent test session
    const isLoading = ref(false)

    // Scenario Selection
    const allScenarios = ref([])

    // New Test Dialog
    const showNewTestDialog = ref(false)
    const newGameSaved = async (savedGame) => {
      logger.debug(`New game saved: ${savedGame.name}`)
      selectedSession.value = savedGame.name
    }

    // Session refresh interval
    const sessionRefresher = ref('')

    // Session list refresh interval
    const sessionListRefresher = ref('')

    const reloadSessionList = async (accessToken) => {
      const sessionGroupList = await allSessionGroups(accessToken, user.value?.sub)
      allSessionIds.value = sessionGroupList?.map((group) => group.sessions)?.flat()?.map((sesh) => sesh.name) ?? []
    }

    const reloadSession = async (accessToken) => {
      if (selectedSession.value) {
        const theSession = await getSession(accessToken, user.value?.sub, { name: selectedSession.value })
        Object.assign(testSession, theSession)
      } else {
        selectedSession.value = allSessionIds.value?.[0] ?? null
      }
    }

    const refreshTestMeta = async (accessToken) => {
      const stateData = await allStateData(accessToken, user.value?.sub, { test: testSession?.test })
      states.value = stateData ?? []
      Object.assign(stateNodes, nodesForStates(states.value))
      Object.assign(stateTransitions, edgesForStateTransitions(stateData))
      const allTestActions = await allActions(accessToken, user.value?.sub, { test: testSession?.test })
      actions.value = allTestActions ?? []
      const testData = await getTest(accessToken, user.value?.sub, { name: testSession?.test })
      testVariables.value = testData?.variables ?? []
    }

    const fetchTestMeta = async () => {
      const accessToken = await getAccessTokenSilently()
      await refreshTestMeta(accessToken)
    }

    const refreshOrgData = async (accessToken) => {
      const orgsData = await allOrgs(accessToken, user.value?.sub)
      orgs.value = orgsData ?? []
      const sponsorData = await allSponsors(accessToken, user.value?.sub)
      sponsors.value = sponsorData ?? []
    }

    const fetchOrgData = async () => {
      const accessToken = await getAccessTokenSilently()
      await refreshOrgData(accessToken)
    }

    const refreshNLPMetas = async (accessToken) => {
      const nlpMetasData = await allNLPMetas(accessToken, user.value?.sub)
      nlpMetas.value = nlpMetasData ?? []
    }

    const fetchNLPMetas = async () => {
      const accessToken = await getAccessTokenSilently()
      await refreshNLPMetas(accessToken)
    }

    const fetchTestData = async () => {
      isLoading.value = true
      states.value = []
      actions.value = []
      allSessionIds.value = []
      testVariables.value = []
      Object.keys(stateNodes).forEach(key => delete stateNodes[key])
      Object.keys(stateTransitions).forEach(key => delete stateTransitions[key])

      // Session and Session List
      const accessToken = await getAccessTokenSilently()
      Object.keys(testSession).forEach(key => delete testSession[key])
      await reloadSessionList(accessToken)
      await reloadSession(accessToken)

      // Session test and other metadata
      if (isManager.value || isSponsor.value) {
        const testsData = await allTests(accessToken, user.value?.sub)
        tests.value = testsData ?? []
        allScenarios.value = testsData?.map(test => ({
          name: test.name,
          archived: test.archived === true ? true : false,
          displayName: test.info?.Name ?? '',
          description: test.info?.Description ?? '',
          icon: test.info?.Icon ?? 'mdi-chart-timeline-variant-shimmer',
          tech: test.info?.Products?.split(',') ?? ["M365"],
          roles: test.info?.Roles?.split(',') ?? [],
          domains: test.info?.Sectors?.split(',') ?? ["Government", "Tech", "Education"]
        })) ?? []
        const nlpModelData = await getNLPModels(accessToken, user.value?.sub)
        nlpModels.value = nlpModelData ?? []
        await refreshTestMeta(accessToken)
        if (isManager.value) {
          await refreshOrgData(accessToken)
          await refreshNLPMetas(accessToken)
        }
      }
      isLoading.value = false
    }

    // Org Edit Dialog
    const showOrgEditDialog = ref(false)
    const selectedOrg = ref(null)

    const newOrgButtonClicked = () => {
      selectedOrg.value = null
      showOrgEditDialog.value = true
    }

    const editSelectedOrg = (org) => {
      selectedOrg.value = org
      showOrgEditDialog.value = true
    }

    const orgSaved = async (savedOrg) => {
      logger.debug(`Org Saved: ${savedOrg.id}`)
      await fetchOrgData()
    }

    // Test Edit Dialog
    const showScenarioEditDialog = ref(false)
    const selectedScenario = ref(null)

    const scenarioNotifVisible = ref(false)
    const scenarioNotifText = ref('')
    const scenarioNotifTimeout = ref(6000)

    const newScenarioButtonClicked = () => {
      selectedScenario.value = null
      showScenarioEditDialog.value = true
    }

    const editSelectedScenario = (scenario) => {
      selectedScenario.value = scenario
      showScenarioEditDialog.value = true
    }

    const scenarioSaved = async (savedScenario) => {
      logger.debug(`Test saved: ${savedScenario.name}`)
      await fetchTestData()
    }

    const removeSelectedTest = async (test) => {
      logger.debug(`Removing selected test ${test?.name}...`)
      scenarioNotifVisible.value = false
      scenarioNotifText.value = ''
      const accessToken = await getAccessTokenSilently()

      // Remove Test
      const { result, message } = await removeTest(accessToken, user.value?.sub, {
        name: test?.name
      })

      if (result) {
        scenarioNotifText.value = 'The scenario was deleted.'
      } else {
        scenarioNotifText.value = message ?? 'The scenario was not deleted. Check logs.'
      }
      scenarioNotifVisible.value = true

      // Refetch all items from server
      await fetchTestData()
    }

    // Sponsor Functions

    const nameForOrgId = (orgId) => {
      return orgs.value?.find((anOrg) => anOrg.id === orgId)?.displayName ?? orgId
    }

    const blockSponsor = async (sponsor) => {
      logger.debug(`Blocking sponsor '${JSON.stringify(sponsor)}'`)
      const accessToken = await getAccessTokenSilently()
      const result = await updateSponsor(accessToken, user.value?.sub, {
        sponsorUserId: sponsor?.user_id,
        blocked: true
      })
      // Refetch org/sponsor data
      await fetchOrgData()
    }

    const unblockSponsor = async (sponsor) => {
      logger.debug(`Unblocking sponsor '${JSON.stringify(sponsor)}'`)
      const accessToken = await getAccessTokenSilently()
      const result = await updateSponsor(accessToken, user.value?.sub, {
        sponsorUserId: sponsor?.user_id,
        blocked: false
      })
      // Refetch org/sponsor data
      await fetchOrgData()
    }

    const removeSponsor = async (sponsor) => {
      // TODO
      logger.debug(`Removing sponsor '${JSON.stringify(sponsor)}'`)
      await fetchOrgData()
    }

    // Sponsor Edit Dialog
    const showSponsorEditDialog = ref(false)
    const selectedSponsor = ref(null)

    const newSponsorButtonClicked = () => {
      selectedSponsor.value = null
      showSponsorEditDialog.value = true
    }

    const sponsorSaved = async (savedSponsor) => {
      logger.debug(`Sponsor Saved: ${JSON.stringify(savedSponsor)}`)
      await fetchOrgData()
    }

    watch(selectedSession, async (val) => {
      if (val) {
        logger.debug(`Session changed to '${val}'`)
        visitRouteForSession(val)
      }
    })

    // Session Functions

    const resetSession = async () => {
      // reset and reload test session
      const updatedSession = await restartSession(await getAccessTokenSilently(), user.value?.sub, { name: selectedSession.value })
      Object.assign(testSession, updatedSession)
    }

    const submitAction = async ({ actionId, state }) => {
      // submit action and reload test session
      const updatedSession = await pickAction(await getAccessTokenSilently(), user.value?.sub, { actionId, session: selectedSession.value, state })
      Object.assign(testSession, updatedSession)
    }

    const getMatchingActionsFunc = async ({ phrase }) => {
      const accessToken = await getAccessTokenSilently()
      // Check for actions matching phrase
      const matches = await rankedActionsForPhrase(accessToken, user.value?.sub, { phrase, session: selectedSession.value })
      setTimeout(async () => {
        await reloadSession(accessToken)
      }, 250)
      return matches
    }

    const submitSessionSettings = async ({ test, archived, roleAssignments, roleAssigneeNames, roleAssigneeTitles, spectators, dispatched, scheduledTime, nlpEnabled, nlpMatchThreshold, nlpModel, nlpAutoDisableThreshold, variables, nlpContext }) => {
      // Submit the settings for the current session
      const updatedSession = await updateSession(await getAccessTokenSilently(), user.value?.sub, { name: selectedSession.value, test, archived, roleAssignments, roleAssigneeNames, roleAssigneeTitles, spectators, dispatched, scheduledTime, nlpEnabled, nlpMatchThreshold, nlpModel, nlpAutoDisableThreshold, variables, nlpContext })
      Object.assign(testSession, updatedSession)
      await fetchTestMeta()
    }

    const updateSessionSettings = async ({ test, archived, roleAssignments, roleAssigneeNames, roleAssigneeTitles, spectators, scheduledTime, nlpEnabled, nlpMatchThreshold, nlpModel, nlpAutoDisableThreshold, variables, nlpContext }) => {
      // Update the settings for the current session (already dispatched)
      const updatedSession = await updateSession(await getAccessTokenSilently(), user.value?.sub, { name: selectedSession.value, test, archived, roleAssignments, roleAssigneeNames, roleAssigneeTitles, spectators, scheduledTime, nlpEnabled, nlpMatchThreshold, nlpModel, nlpAutoDisableThreshold, variables, nlpContext })
      Object.assign(testSession, updatedSession)
      await fetchTestMeta()
    }

    const updateSessionScenario = async ({ test }) => {
      // Update the scenario (test) for the current session
      logger.debug(`Updating session scenario with: ${test?.name}`)
      if (test?.name) {
        const updatedSession = await updateSession(await getAccessTokenSilently(), user.value?.sub, { name: selectedSession.value, test: test.name })
        Object.assign(testSession, updatedSession)
        await fetchTestMeta()
      }
    }

    const postMessage = async ({ message }) => {
      // Post a message to the current game (session)
      logger.debug(`Adding message to session '${selectedSession.value}': ${message}`)
      const updatedSession = await addSessionMessage(await getAccessTokenSilently(), user.value?.sub, { session: selectedSession.value, message })
      Object.assign(testSession, updatedSession)
    }

    // User APIs

    const getUserStatus = async ({ emails }) => {
      logger.debug(`Requesting user status for emails: ${JSON.stringify(emails)}...`)
      const userStatus = await getUserData(await getAccessTokenSilently(), user.value?.sub, { emails })
      return userStatus
    }

    const inviteUserToSession = async ({ email, session }) => {
      logger.debug(`Re-inviting user '${email}' to session '${session}'...`)
      const invitationResult = await reInviteUser(await getAccessTokenSilently(), user.value?.sub, { email, session })
      return invitationResult
    }

    const loadAttachmentSource = async ({ test, action, state, name, extension }) => {
      logger.debug(`Loading attachment data for test '${test}', action '${action}', state: '${state}', name: '${name}' and extension: '${extension}'...`)
      const source = await getAttachmentSource(await getAccessTokenSilently(), user.value?.sub, { test, action, state, name, extension })
      return source
    }

    // Component Lifecycle Hooks
    onErrorCaptured((error, instance, info) => {
      logger.debug(`Error captured: '${error}' in instance '${JSON.stringify(instance)}' with info: '${JSON.stringify(info)}'`)
    })

    const errorLogger = (e) => {
          console.error(`Uncaught error: ${e.message}`)
            // prevent other listeners from firing
          e.stopImmediatePropagation()
            // prevent the browser's console error message
          e.preventDefault()
      }

    onMounted(async () => {
      window.addEventListener('error', errorLogger)
      window.addEventListener('resize', windowResizeHandler)
      const sessionListHandler = async () => {
        try {
          if (
            user.value
            && selectedTab.value === 'player'
            && (isUser.value || isManager.value || isSponsor.value)
            )
           {
            const accessToken = await getAccessTokenSilently()
            if (accessToken) {
              await reloadSessionList(accessToken)
            }
          }
        } catch (error) {
          logger.error(`Error refreshing session list: ${error}`)
          // Log out
          // logout({
          //   logoutParams: {
          //     returnTo: window.location.origin
          //   } 
          // })
        }
      }
      sessionListRefresher.value = setInterval(sessionListHandler, 20000)
      sessionListHandler()
      sessionRefresher.value = setInterval(async () => {
        try {
          if (
            user.value
            && selectedTab.value === 'player'
            && ((isUser.value || isManager.value || isSponsor.value) && testSession.dispatched === true)
            )
           {
            const accessToken = await getAccessTokenSilently()
            if (accessToken) {
              await reloadSession(accessToken)
            }
          }
        } catch (error) {
          logger.error(`Error refreshing test session: ${error}`)
          // Log out
          // logout({
          //   logoutParams: {
          //     returnTo: window.location.origin
          //   } 
          // })
        }
      }, 5000)
    })

    onBeforeUnmount(() => {
      clearInterval(sessionRefresher.value)
      clearInterval(sessionListRefresher.value)
      window.removeEventListener('resize', windowResizeHandler)
      window.removeEventListener('error', errorLogger)
    })
    
    // Theme Mgmt
    const currentTheme = ref('dark')
    const darkThemeSwitch = ref(true)

    watch(darkThemeSwitch, (val) => {
      if (val) {
        currentTheme.value = 'dark'
        stateConfigs.node.label.color = '#ffffff'
      } else {
        currentTheme.value = 'light'
        stateConfigs.node.label.color = '#000000'
      }
    })

    // External Links

    // const visitAuth0 = () => {
    //   window.open('https://auth0.com')
    // }

    // User Authentication
    const logInOrOut = () => {
      if (isAuthenticated.value === true) {
        // Log out
        logout({
          logoutParams: {
            returnTo: window.location.origin
          } 
        })
      } else {
        loginWithRedirect({ appState: { target: window.location.pathname } })
      }
    }

    // Load User content when auth status changes to true
    watch(isAuthenticated, async (isAuthd) => {
      if (isAuthd) {
        logger.debug(`Current login status: ${isAuthd}`)
        // Register user's metadata
        await registerUser(await getAccessTokenSilently(), user.value?.sub, {
          email: user.value?.email,
          picture: user.value?.picture,
          name: user.value?.name
        })

      } else {
        // redirect to the splash
        visitHome()
      }
    }, { immediate: true })

    const isUser = ref(false)
    const isManager = ref(false)
    const isSponsor = ref(false)

    const setUserRole = async (roles) => {
      isUser.value = false
      isManager.value = false
      isSponsor.value = false
      if (roles) {
        if (roles.includes('manager')) {
          isManager.value = true
        } else if (roles.includes('sponsor')) {
          isSponsor.value = true
        }
        else {
          isUser.value = true
          componentTitle.value = '💥ChaosTrainer'
          if (!route.params.navSession) {
            checkRouteAndPush(`/admin`)
          }
        }
        await fetchTestData()
      }
    }

    watch(user, (val) => {
      logger.debug(`User data changed to: ${JSON.stringify(val, null, 2)}`)
      setUserRole(val?.['https://www.chaostrack.com/roles'])
      // Redirect authenticated non-managers to the client app
      if (val?.sub) {
        logger.debug(`Checking user for client redirect... ${JSON.stringify(val)}`)
        if (!isManager.value) {
          visitClientApp()
        }
      }
    }, { immediate: true })

    // Graph Visualizations
    const stateNodes = reactive({})
    const stateTransitions = reactive({})
    const stateConfigs = reactive(
      vNG.defineConfigs({
        view: {
          scalingObjects: true,
          minZoomLevel: 0.1,
          maxZoomLevel: 5,
          autoPanAndZoomOnLoad: 'fit-content'
        },
        node: {
          label: {
            color: '#ffffff',
            visible: true
          }
        },
        edge: {
          marker: {
            target: {
              type: 'arrow'
            }
          }
        }
      })
    )

    // NLP Model Data Import
    const nlpImporterRef = ref(null)
    const isNLPImporting = ref(false)
    const nlpImportStatusMsg = ref('')
    const importNLPFiles = async (event) => {
        isNLPImporting.value = true
        // Get list of files
        const arr = Array.from(event.target.files)

        const parseFilesPromises = arr.map(file => {
          return new Promise((resolve, reject) => {
            const fileName = `${file.name}`
            logger.debug(`Parsing NLP file '${fileName}' (size: ${file.size})...`)
            const reader = new FileReader()
            reader.readAsArrayBuffer(file)
            reader.onload = (event) => {
              const buff = Buffer.from(event.target.result)
              logger.debug(`Finished parsing data from NLP '${fileName}'.`)
              resolve({ data: buff, fileName })
            }
            reader.onerror = () => {
              logger.error(`Error parsing NLP file '${fileName}'!`)
              reject()
            }
          })
        })

        const fileDataList = await Promise.all(parseFilesPromises)
        const loadDataPromises = fileDataList.map(({ data: fileBinaryContent, fileName }) => {

          const onCompleteChunk = (percentComplete) => {
            nlpImportStatusMsg.value = `Loading... (${percentComplete.toPrecision(3)}% done.)`
          }
          return new Promise((resolve, reject) => {
            nlpImportStatusMsg.value = 'Loading...'
            getAccessTokenSilently().then((accToken) => {
              importNLPDataFromFile(accToken, user.value?.sub, {
                fileBuffer: fileBinaryContent,
                name: fileName
              }, onCompleteChunk).then((results) => {
                if (results) {
                  logger.debug(`Got NLP file upload result: ${JSON.stringify(results)}`)
                  nlpImportStatusMsg.value = `Success! Loaded NLP model file ${fileName}.`
                  resolve()
                } else {
                  nlpImportStatusMsg.value = 'Failed! There was a problem importing the selected NLP model file.'
                  resolve()
                }
              }).catch((err) => {
                reject(err)
              })
            }).catch((err) => {
              reject(err)
            })
          })
        })
        await Promise.all(loadDataPromises)
        // Refetch all items from server
        await fetchTestData()
        // Reset Input/Messaging
        logger.debug('Starting input reset timer...')
        setTimeout(() => {
          isNLPImporting.value = false
          nlpImportStatusMsg.value = ''
          if (nlpImporterRef.value) {
            nlpImporterRef.value.value = '' // reset upload selection
          }
        }, 5000);
    }

    return {
      // Router
      queryParams,
      componentTitle,
      componentSubTitle,
      footerCopywriteHolder,
      projectVersion,
      currentTheme,
      darkThemeSwitch,
      smAndUp, // size class bool
      mdAndUp, // size class bool
      windowHeight,
      // Tab + Window Management
      selectedSession,
      selectedTab,
      // Test Data
      isLoading,
      actions,
      states,
      nlpModels,
      nlpMetas,
      tests,
      testVariables,
      testSession,
      allSessionIds,
      // Org Data
      orgs,
      sponsors,
      // Scenario Selection
      allScenarios,
      // New Test Dialog
      showNewTestDialog,
      newGameSaved,
      // Org Edit Dialog
      showOrgEditDialog,
      selectedOrg,
      newOrgButtonClicked,
      editSelectedOrg,
      orgSaved,
      // NLP Dialog
      fetchNLPMetas,
      // Test Edit Dialog
      scenarioNotifVisible,
      scenarioNotifText,
      scenarioNotifTimeout,
      showScenarioEditDialog,
      selectedScenario,
      newScenarioButtonClicked,
      editSelectedScenario,
      scenarioSaved,
      removeSelectedTest,
      // Sponsor Functions
      blockSponsor,
      unblockSponsor,
      removeSponsor,
      nameForOrgId,
      // Sponsor Edit Dialog
      showSponsorEditDialog,
      selectedSponsor,
      newSponsorButtonClicked,
      sponsorSaved,
      // Test Session Functions
      resetSession,
      submitAction,
      getMatchingActionsFunc,
      submitSessionSettings,
      updateSessionSettings,
      updateSessionScenario,
      postMessage,
      loadAttachmentSource,
      // User APIs
      getUserStatus,
      inviteUserToSession,
      // Import NLP Files
      nlpImporterRef,
      isNLPImporting,
      nlpImportStatusMsg,
      importNLPFiles,
      // Graph visualization UI
      stateNodes,
      stateTransitions,
      stateConfigs,
      // Authentication
      isAuthenticated,
      user,
      isManager,
      isSponsor,
      isUser,
      isEmailUser,
      logInOrOut,
      // Routing
      currentPath,
      visitHome,
      visitAdmin,
      visitClientApp
    }
  }
}
</script>

<style lang="scss" scoped>
.fixed-content {
    top: 0;
    bottom:0;
    position:fixed;
    overflow-y:scroll;
    overflow-x:hidden;
}
</style>